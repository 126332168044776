import axios from "axios";
import qs from "qs";
import { getStrapiURL } from "./api";

export const api = axios.create({
  baseURL: getStrapiURL(),
});

export async function getProdutos() {
  const { data } = await api.get("/produtos");
  return data;
}
//oooooooooliiiiiiiiiiiiiiii
// export async function getByFilter() {
//   const { data } = await api.get("/produtos");
//   return data;
// }

// interface FilterProps {
//   queryKey: {
//     filtro: {
//       key: string;
//       value: number | undefined;
//     }[];
//     page: number;
//     showPosts: number;
//     itemOffset: number;
//   }[];
// }

export async function filtrarProdutos(filters: any) {
  let filtro = filters.queryKey[1].filtro;
  let page = filters.queryKey[2].page;
  let showPosts = filters.queryKey[2].showPosts;
  let itemOffset = filters.queryKey[2].itemOffset;
  let order = filters.queryKey[2].order;
  let orderType: string[] = [];
  switch (order) {
    case "most_popular":
      orderType = ["avaliacao:DESC"];
      break;
    case "name_asc":
      orderType = ["titulo:ASC"];
      break;
    case "name_desc":
      orderType = ["titulo:DESC"];
      break;
    case "news":
      orderType = ["published_at:DESC"];
      break;
    default:
      break;
  }

  // filters params
  const categoriesParams = filtro.map((filter: any) =>
    filter.key === "categorias" ? filter.value : undefined
  );
  const subcategoriesParams = filtro.map((filter: any) =>
    filter.key === "subcategorias" ? filter.value : undefined
  );
  const linesParams = filtro.map((filter: any) =>
    filter.key === "linhas" ? filter.value : undefined
  );
  const ambientesParams = filtro.map((filter: any) =>
    filter.key === "ambientes" ? filter.value : undefined
  );
  const acabamentosParams = filtro.map((filter: any) =>
    filter.key === "acabamentos" ? filter.value : undefined
  );
  const coresParams = filtro.map((filter: any) =>
    filter.key === "cores" ? filter.value : undefined
  );
  const aplicacoesParams = filtro.map((filter: any) =>
    filter.key === "aplicacoes" ? filter.value : undefined
  );
  const modelosParams = filtro.map((filter: any) =>
    filter.key === "modelos" ? filter.value : undefined
  );
  const sistemasParams = filtro.map((filter: any) =>
    filter.key === "sistemas" ? filter.value : undefined
  );

  // Fetch query produtos
  const products = await api.get("/produtos", {
    params: {
      categorias: categoriesParams,
      subcategorias: subcategoriesParams,
      linhas: linesParams,
      ambientes: ambientesParams,
      acabamentos: acabamentosParams,
      cores: coresParams,
      aplicacoes: aplicacoesParams,
      modelos: modelosParams,
      sistemas: sistemasParams,
      _sort: orderType,
      _limit: showPosts,
      _start: itemOffset,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });

  // Fetch query count produtos
  const total = await api.get("/produtos/count", {
    params: {
      categorias: categoriesParams,
      subcategorias: subcategoriesParams,
      linhas: linesParams,
      ambientes: ambientesParams,
      acabamentos: acabamentosParams,
      cores: coresParams,
      aplicacoes: aplicacoesParams,
      modelos: modelosParams,
      sistemas: sistemasParams,
      _limit: -1,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });

  return { products: products.data, total: total.data };
}

export async function filtrarFaq(filter: any) {
  let filtro = filter.queryKey[1].filtro;

  // Fetch query produtos
  const faq = await api.get("/faqs", {
    params: {
      _sort: ["id:DESC"],
      _where: {
        _or: [
          { pergunta_contains: filtro },
          { resposta_contains: filtro },
          { "faq_categoria.nome_contains": filtro },
        ],
      },
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });

  return { faq: faq.data };
}

export async function filtrarRepresentante(filter: any) {
  let filtro = filter.queryKey[1].filtro;

  // Fetch query produtos
  const representante = await api.get("/representantes", {
    params: {
      _where: {
        _or: [
          { pergunta_contains: filtro },
          { resposta_contains: filtro },
          { "faq_categoria.nome_contains": filtro },
        ],
      },
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });

  return { representante: representante.data };
}

export async function filtrarEstado(id: string, tipo: string) {
  let estadoId = id;
  let paramsFilter = {};
  if (estadoId !== "all") {
    paramsFilter = {
      tipo: tipo,
      _representante_estado: estadoId,
      _sort: "nome:ASC",
      _limit: -1,
    };
  } else {
    paramsFilter = {
      tipo: tipo,
      _sort: "nome:ASC",
      _limit: -1,
    };
  }

  const res = await api.get("/representantes", {
    params: paramsFilter,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  const representantes = res.data;

  const cidades = representantes.map((representante: any) => {
    return representante.representante_cidade;
  });

  var result = cidades.reduce((unique: any, o: any) => {
    if (o && o.id && !unique.some((obj: any) => obj.id === o.id)) {
      unique.push(o);
    }
    return unique;
  }, []);
  return result.sort((a: any, b: any) => {
    // ordena por nome
    return a.nome?.localeCompare(b.nome);
  });
}

type paramsFilter = {
  tipo: string;
  _representante_estado?: string;
  _representante_cidade?: string;
  _representante_bairro?: string;
  _sort?: string;
  _limit?: number;
};

export async function filtrarCidade(
  estadoId: string,
  cidadeId: string,
  tipo: string
) {
  let paramsFilter: paramsFilter = {
    tipo: tipo,
    _sort: "nome:ASC",
    _limit: -1,
  };

  if (estadoId !== "all") {
    paramsFilter._representante_estado = estadoId;
  }
  if (cidadeId !== "all") {
    paramsFilter._representante_cidade = cidadeId;
  }
  const res = await api.get("/representantes", {
    params: paramsFilter,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  const representantes = res.data;

  const bairros = representantes.map((representante: any) => {
    return representante.representante_bairro;
  });

  var result = bairros.reduce((unique: any, o: any) => {
    if (o && o.id && !unique.some((obj: any) => obj.id === o.id)) {
      unique.push(o);
    }
    return unique;
  }, []);

  return result.sort((a: any, b: any) => {
    // ordena por nome
    return a.nome?.localeCompare(b.nome);
  });
}

export async function filtrarRepresentantes(
  estadoId: string,
  cidadeId: string,
  bairroId: string,
  tipo: string
) {
  let paramsFilter: paramsFilter = {
    tipo: tipo,
    _sort: "nome:ASC",
    _limit: -1,
  };

  if (estadoId !== "all") {
    paramsFilter._representante_estado = estadoId;
  }
  if (cidadeId !== "all") {
    paramsFilter._representante_cidade = cidadeId;
  }
  if (bairroId !== "all") {
    paramsFilter._representante_bairro = bairroId;
  }
  const res = await api.get("/representantes", {
    params: paramsFilter,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  const representantes = res.data;

  return representantes;
}
